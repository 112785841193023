
import { BlockManager, BasicType, AdvancedType, JsonToMjml } from 'easy-email-core';
import { EmailEditor as Editor, EmailEditorProvider, IEmailTemplate } from 'easy-email-editor';
import { ExtensionProps, StandardLayout } from 'easy-email-extensions';
import { useWindowSize } from 'react-use';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
// theme, If you need to change the theme, you can make a duplicate in https://arco.design/themes/design/1799/setting/base/Color
import '@arco-themes/react-easy-email-theme/css/arco.css';
import { Box, IconButton, Select, Typography, debounce } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AppButton from '../../../../../AppComponents/AppButton';
import styled from 'styled-components';
import { useNotification } from '../../../../../Hooks/useNotification/useNotification';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../Store';
import { EmailActions } from '../../../../../Store/Slices/email/email.actions';
import { useSelector } from 'react-redux';
import { useCurrentBot } from '../../../../../Store/Slices/DashboardSlices';
import { ChatbotConsoleService } from '../../../../../Services/Apis/ChatbotConsoleService';
import Input from '../../../../../Modules/FlowBuilder/Components/Input';
import { LinkText } from '../../../../../Customization/CommonElements';
import { DeleteCannedResponse } from '../../../WhatsApp/Templates/TemplatesListing';
import AppModel from '../../../../../Hooks/useModel/AppModel';
import { theme } from '../../../../../Customization/Theme';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import { AppMaterialIcons } from '../../../../../AppComponents/AppMaterialIcons';
import { AppForm, AppFormField, AppSubmitButton } from '../../../../../AppComponents/Forms';
import { GridFooterContainer } from '@mui/x-data-grid';
import AppInputField from '../../../../../AppComponents/AppInputField';
import { useFormikContext } from 'formik';

var mjml2html = require('mjml-browser');

const fullScreenStyle = {
    position: 'fixed',
    top: '0',
    left: '4rem',
    width: 'calc(100% - 4rem)',
    zIndex: '999',
    height: '100%',
}

const exitFullscreencss = {
   
    "@media (max-width: 1440px)": {
        width: '300px !important',
        minWidth: '300px !important',
        maxWidth: '300px !important',
    },


    position: 'absolute',
    right: '0%',
    zIndex: '1',
    display: 'inline-table',
    height: '52px',
    left:'0',
    bottom: '1%',
    maxWidth: '360px',
    width: '360px',

    button: {
        ":hover": {
            backgroundColor: "rgb(33, 28, 82) !important",
            color: "white !important",
        }
    }

}


const Yakou = (props: any) => {
    const { width } = useWindowSize();
    const [showSaveAsTemplate, setShowSaveAsTemplate] = useState<boolean>(false);
    const [templateValues, setTemplateValues] = useState<any>();
    const onchange = (evt: any) => {
        setTemplateValues(evt)
        if (JSON.stringify(evt) !== JSON.stringify(props.initialValues)) {
            setShowSaveAsTemplate(true)
        } else {
            setShowSaveAsTemplate(false)

        }
        const mjmlString = JsonToMjml({
            data: evt?.content,
            mode: 'production',
            context: evt?.content,
        });
        const html = mjml2html(mjmlString, {}).html;
        // setShowSaveAsTemplate(true)

        localStorage.setItem("HTMLTemplate", JSON.stringify(html));
        localStorage.setItem("JSONTemplate", JSON.stringify(evt));
    }
    const { showAlert } = useNotification()
    const dispatch = useAppDispatch()
    const currentBot = useSelector(useCurrentBot);
    const [value, setValue] = useState<string>("");
    const [isShowingModal, toggleModal] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [name, setName] = useState<boolean>(false);
    const [templateName, setTemplateName] = useState<any>("");
    const [contactPropertiesList, setContactPropertiesList] = useState<any>([])
    const [allContactPropertiesList, setAllContactPropertiesList] = useState<any>([])
    const [isFullScreenMode, setIsFullScreenMode] = useState<boolean>(false)
    const [selectCustomField, setSelectCustomField] = useState<any>()
    const [DefaultContactPropertiesField, setDefaultContactPropertiesField] = useState<any[]>(["first_name", "last_name", "email", "mobile_phone_number", "street_address", "city", "state_region", "postal_code", "country_region"])
    const CopyTextToClipboard = async (value: string) => {
        let temp: any = "{{" + value + "}}";
        await navigator.clipboard.writeText(temp);
        showAlert("Name copied clipboard", "success");
    };
    const getContactPropertiesListValue = () => {
        dispatch(EmailActions.getContactPropertiesList({}))
            .then((res: any) => {
                if (res?.payload?.data?.status === 200) {
                    let fieldArray: any[] = []
                    res?.payload?.data?.data.map((res: any) => {
                        if (DefaultContactPropertiesField.includes(res.name)) {
                            fieldArray.push(res)
                        }
                    })
                    setContactPropertiesList(fieldArray)
                    setAllContactPropertiesList(res?.payload?.data?.data)
                } else {
                    showAlert(
                        res?.payload?.data?.error,
                        "error"
                    );
                }
            })
            .catch((error: any) => {
                showAlert(
                    "Template Name Already Exist",
                    "error"
                );
            })
            .finally(() => {
            });
    }
    useEffect(() => {
        getContactPropertiesListValue()
    }, [])
    useEffect(() => {
        if(props?.isNewTemplate){
            setName(true)
        }
    },[props?.isNewTemplate])
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (event.target.value) {
            let searchList = allContactPropertiesList.filter((key: any) => key.label.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()));
            setContactPropertiesList(searchList)
        } else {
            getContactPropertiesListValue()
        }

    };
    const displayNameProperties = () => {
        return (
            <EmailListigFilter>
                <Input
                    placeholder="Search"
                    onChange={handleChange}
                    value={value}
                    style={{ width: "21rem", margin: "2px 15px" }}
                />
                <table>

                    {contactPropertiesList.map((res: any) => {
                        return (
                            <tr>
                                <td style={{ width: "185px" }}>{res.label}</td>
                                <td style={{ width: "150px" }}>
                                    <CopyBtnBox>
                                        <IconButton onClick={() => CopyTextToClipboard(res.name)}><ContentCopyIcon /></IconButton>
                                    </CopyBtnBox>
                                </td>
                            </tr>
                        )
                    })}
                    <LinkText
                        sx={{ margin: "1rem 0rem" }}
                        variant="subtitle2"
                    >
                        <span style={{color:"#5858e6"}} onClick={() => toggleModal(true)}> More Custom Properties</span>
                    </LinkText>
                </table>
            </EmailListigFilter>
        )
    }
    const categories: ExtensionProps['categories'] = [
        {
            label: 'Content',
            active: false,
            blocks: [
                {
                    type: AdvancedType.TEXT,
                },
                {
                    type: AdvancedType.IMAGE,
                    payload: { attributes: { padding: '0px 0px 0px 0px', alt: ' image' } },

                },
                {
                    type: AdvancedType.BUTTON,
                },
                {
                    type: AdvancedType.SOCIAL,
                },
                {
                    type: AdvancedType.DIVIDER,
                },
                {
                    type: AdvancedType.SPACER,
                },
                {
                    type: AdvancedType.HERO,
                },
                {
                    type: AdvancedType.WRAPPER,
                },
            ],
        },
        {
            label: 'Contact Properties',
            active: true,
            displayType: 'custom',
            blocks: [displayNameProperties()]
        },
        {
            label: 'Layout',
            active: false,
            displayType: 'column',
            blocks: [
                {
                    title: '2 columns',
                    payload: [
                        ['50%', '50%'],
                        ['33%', '67%'],
                        ['67%', '33%'],
                        ['25%', '75%'],
                        ['75%', '25%'],
                    ],
                },
                {
                    title: '3 columns',
                    payload: [
                        ['33.33%', '33.33%', '33.33%'],
                        ['25%', '25%', '50%'],
                        ['50%', '25%', '25%'],
                    ],
                },
                {
                    title: '4 columns',
                    payload: [[['25%', '25%', '25%', '25%']]],
                },
            ],
        }
    ];

    const onUploadImage = async (blob: Blob) => {
        const formData = new FormData();
        formData.append('source', blob);
        let url = ""
        await ChatbotConsoleService.uploadWhatsappMedia(formData, true)
            .then((response: any) => {
                url = response?.data?.source
            })
            .catch((error: any) => {
                showAlert("error while creating attachment", error)
            });
        return url
    };
    const save = (values:any) => {
        setName(false)
        props.saveEmailTemplate(templateName,props?.isNewTemplate,values)
        .then((success:any) => {
            if (success) {

            } else {
                setName(true)
                setIsError(true) 
            }
        })
        .catch((error:any) => {
            setName(true)
            setIsError(true) 
        });
    }
    return (<> <EmailEditorProvider
        data={props.initialValues}
        height={props?.isFullScreen ? "calc(100vh - 64px)" : 'calc(100vh - 272px)'}
        autoComplete
        onUploadImage={onUploadImage}
        dashed={false}
        fontList={[
                    { label: 'Arial', value: 'Arial, sans-serif' },
                    { label: 'Georgia', value: 'Georgia, serif' },
                    { label: 'Times New Roman', value: 'Times New Roman, serif' },
                    { label: 'Courier New', value: 'Courier New, monospace' },
                    { label: 'Roboto', value: 'Roboto, sans-serif' }
                ]}
        
        
    >
        {({ values }: any) => {
            onchange(values)
            return (
                <div style={{ position: "relative" }}>
                    <EditorBtnWrapper isFullScreen={props?.isFullScreen}>
                        {props.handleBack && !props?.isBroadcast && <AppButton className="positionBtn back" variant='outline' onClick={props.handleBack}>
                            Back
                        </AppButton>}
                        {props?.isBroadcast ? <> <div className="left">
                            {(
                                <IconButton onClick={() => props?.handlePageBack(1)}>
                                    <AppMaterialIcons
                                        iconName={"ArrowBackIosRounded"}
                                        style={{ fontSize: "0.8rem" }}
                                    />
                                </IconButton>
                            )}
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <p className="heading" style={{ padding: "8px 0", marginLeft: "4px" }}>
                                    {props?.isNewTemplate ? templateName : props?.templateName}
                                </p>
                            </Box>
                        </div>
                            <div>
                                <a>Template Name:- {props?.isNewTemplate ? templateName : props?.templateName}</a> <a onClick={props.handleBack} style={{
                                    marginLeft: "15px",
                                    color: "blue",
                                    cursor: "pointer"
                                }}> Change Template</a>
                            </div> </> : null}
                        {/* <AppButton variant='outline' className="positionBtn preview" onClick={() => { }}>
                            <VisibilityOutlinedIcon style={{ fontSize: "30px" }} />
                        </AppButton> */}
                        <div className="right" style={{ display: "flex" }}>
                            {props?.isBroadcast ?
                                <>
                                    {!props?.isNewTemplate ? <> {showSaveAsTemplate &&  props?.templateType === "gallery" ? <AppButton variant='outline' style={{    textTransform: "math-auto"}} onClick={() => setName(true)} >
                                        {"Save as a Template"}
                                    </AppButton> :null  }
                                    {props?.templateType === "savedTemplate"? <AppButton onClick={() => props.onSubmit(values)} style={{width: "115px"}} variant='outline' >
                                        {"Continue"}
                                    </AppButton> : null}
                                    {props?.templateType === "gallery" ? <AppButton className="positionBtn save" onClick={() => props.onSubmit(values)} style={{width: "115px"}}>Continue</AppButton> : showSaveAsTemplate? <AppButton className="positionBtn save" onClick={() => props.updateTemplateAndSubmit(values)}>Update & Continue</AppButton> :null}
                                    </> :<AppButton onClick={() => save(values)} variant='outline' >
                                        {"Save & Continue"}
                                    </AppButton>}
                                </>
                                : <>
                                <AppButton onClick={() => setIsFullScreenMode(!isFullScreenMode)}>{isFullScreenMode ? 'Exit Full Screen' : "Full Screen"}</AppButton>
                                <AppButton className="positionBtn save" onClick={() => props.onSubmit(values)}>Save & Continue</AppButton>  </>}
                              


                        </div>
                    </EditorBtnWrapper>
                    <EmailEditorWrapper isFullScreen={isFullScreenMode}  style={isFullScreenMode ? fullScreenStyle : {}}>

                    <StandardLayout
                        compact={true}
                        showSourceCode={false}
                        categories={categories}
                        mjmlReadOnly={true}
                        >
                        <Editor />
                    </StandardLayout>
                    {
                        isFullScreenMode && <Box sx={exitFullscreencss}>
                        <AppButton style={{ width: "100%", height: '100%',   }} onClick={() => setIsFullScreenMode(!isFullScreenMode)}>{isFullScreenMode ? 'Exit Full Screen' : "Full Screen"}</AppButton>
                    </Box>
                    }
                    
                        </EmailEditorWrapper>   
                </div>
            );
        }}
    </EmailEditorProvider>
        <AppModel
            isShowing={isShowingModal}
            onClose={toggleModal}
            divStyle={{
                padding: 0,
            }}
            PaperProps={{
                style: {
                    boxShadow: "none",
                    width: "50rem"
                },
            }}
        >
            <DeleteCannedResponse>
                <div className='header'>
                    <IconButton
                        aria-label="close"
                        onClick={() => { toggleModal(false) }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className='header-left'>
                        <div className='danger'>
                        </div>
                        <div className='text'>
                            <PropertiesTitle>Custom Properties</PropertiesTitle>
                            <MenuItemWapper>
                                {allContactPropertiesList.map((item: any, index: any) => (
                                    <MenuItem onClick={() => { CopyTextToClipboard(item.name) }}>{item.label}</MenuItem>
                                ))}</MenuItemWapper>
                        </div>
                    </div>
                </div>
                <div className='footer'>
                    <AppButton variant='grey' onClick={() => { toggleModal(false); }} >
                        Done
                    </AppButton>
                </div>
            </DeleteCannedResponse>
        </AppModel >
        <AppModel
            isShowing={name}
            divStyle={{
                padding: 0,
            }}
            PaperProps={{
                style: {
                    width: "450px",
                },
            }}
        >
            <AppForm
                initialValues={{ formName: "" }}
                onSubmit={(values: any) => {
                    setName(false)
                }}
            >
                <StyledModelHeader>
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            Enter Template Name
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={() => {props?.isNewTemplate && templateName == "" ? props.handleBack() :setName(false)}}>
                            <AppMaterialIcons
                                iconName={"Close"}
                                style={{ fontSize: "1.2rem" }}
                            />
                        </IconButton>
                    </Box>
                </StyledModelHeader>
                <AppFormModalContainer direction="column">
                    <GridContainer>
                        <AppInputField
                            name="template_name"
                            value={templateName}
                            divStyle={{ width: "300px" }}
                            label="Template Name"
                            onChange={(e: any) => setTemplateName(e.target.value)}
                            errorMessage=""
                            style={{
                                border: `1px solid ${theme.palette.default.BorderColorGrey}`,
                            }}
                        />
                    </GridContainer>
                    <div className='saveButton'>
                        <AppButton variant='grey' onClick={() => {props?.isNewTemplate && templateName == "" ? props.handleBack() :setName(false)}}>Cancel</AppButton>
                        <AppButton onClick={() => {isError ? save(templateValues) : !props?.isNewTemplate ? save(templateValues): setName(false)}}>Save</AppButton>

                    </div>
                </AppFormModalContainer>
            </AppForm>
        </AppModel>
    </>)
}

export default Yakou;
const CopyBtnBox = styled.div`
    margin-left: 100px;
`
const StyledModelHeader = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    height: 56px;
    min-height: 56px;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    position: sticky;
    background-color: ${theme.palette.default.background};
    top:0;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    h6 {
      font-size: 1.05rem;
      color: ${theme.palette.default.text};
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }

    .footer {
    }
  }
`;
const AppFormModalContainer = styled.div`
&&{
  display:flex;
  flex-direction: ${(props: { direction: string }) => props.direction};
  justify-content:start;
  padding: 10px 27px;
  gap:15px;
  .saveButton{
    width:100%;
    display:flex;
    justify-content:end;
    gap:20px;
  }
}`
const GridContainer = styled.div`
&&{
  display:flex;
  gap:10px;
}`
const EmailListigFilter = styled.div`
    min-width:-webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const EditorBtnWrapper = styled.div`
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: ${(props: any) => props?.isFullScreen ? "-64px" : "-53px"};
    background-color: ${(props: any) => props?.isFullScreen ? "#fff" : "transparent"};
    height: ${(props: any) => props?.isFullScreen && "64px"};
    right: ${(props: any) => props?.isFullScreen ? "0px" : "0px"};
    width: ${(props: any) => props?.isFullScreen && "100%"};
    padding-right: ${(props: any) => props?.isFullScreen && "1rem"};
    button {
        margin-left: 10px;
    }
    .left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p.heading {
            font-size: 16px;
            color: ${theme.palette.default.black};
            font-weight: 500;
        }
    }

    .test-tamplate__btn {
        display: flex;
        gap: 0.5rem;
        min-width: 287px;
        .back-btn {
            

        }
    }

`
const MenuItem = styled.div`
    border-radius: 69px;
    text-align: center;
    max-width: max-content;
    padding: 12px;
    color: green;
    cursor: pointer;
    margin: 11px 7px;
    padding: 5px 10px 5px 10px;
}
`
const MenuItemWapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`
const PropertiesTitle = styled.h4`
margin : 11px 6px 13px
`

const EmailEditorWrapper = styled.div`

${({ isFullScreen }: any) =>
    isFullScreen &&
    `
    .arco-card {
       
          height: 100%;
        .arco-card-body {
         
          height: 100% !important;
        }
      }
      section {
     
          height: 100% !important;
        
        aside {

        .os-content {
        
         > div:first-of-type {
      
      height: calc(100vh - 165px) !important;
    }
        }
       
          
          height: 100% !important;
          
        }
      }
  `}


  @media (max-width: 1440px) {
    ${({ isFullScreen }: any) =>
      isFullScreen &&
      `
      .arco-card {
       
          height: 100%;
        .arco-card-body {
          
          height: 100% !important;
        }
      }
      section {
        
        //   display: flex;
          height: 100% !important;
        .arco-layout {
         
          > div:first-of-type {
            width: auto !important;
            min-width: auto !important;
         
        }
          
        }
        aside {
        .os-content {
        
         > div:first-of-type {
      
      height: calc(100vh - 165px) !important;
    }
        }
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important;
          
          height: 100% !important;
         
        }
      }
    `}
  }
`;
