import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { AppForm, AppFormObserver, AppSubmitButton } from "../../../AppComponents/Forms";
import AppFormTimePicker from "../../../AppComponents/Forms/AppFormTimePicker";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppSelector } from "../../../Store";
import { automationBuilderPayload, automationBuilderState } from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import { closeConfigModal, setNodeConfig } from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { saveGraph } from "../config";
import { secondsConverter } from "../utils";
import { DelayConfigSchema } from "../validationSchema";

const AddDelay = (): JSX.Element => {
  const formRef: any = useRef();
  const dispatch = useDispatch();
  const { showAlert } = useNotification();
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [formikProps, setFormikProps] = useState<any>(null);
  const delayPeriod = [
    {
      name: "Seconds",
      id: "Seconds",
    },
    {
      name: "Minutes",
      id: "Minutes",
    },
    {
      name: "Hours",
      id: "Hours",
    },
    {
      name: "Days",
      id: "Days",
    },
    {
      name: "Week",
      id: "Week",
    }
  ]
  const handleSubmit = async (values: any, submitProps: any) => {
    const seconds: number = secondsConverter(values?.delayPeriod?.name, values?.delay);
    let payload: any = {
      nodeObject: {
        delay: seconds,
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText: `Wait for ${values?.delay} ${values?.delayPeriod?.name} and resume`
    }
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    await saveGraph();
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  }
  const [initialValues, setInitialValues]: any = useState({
    delay: ""
  })
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  }
  
  useEffect(() => {
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("smsTemplateId", "", { shouldDirty: true })
  }, []);

  useEffect(() => {
    if (automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.delay) {
      const delayAction = automationPayload?.draftedUIObject?.uiObject?.cells?.filter((cell: any) => cell.type === "Action.delay-add" && automationReduxData?.selectedNodeId === cell.id)[0];
      const time = delayAction?.markup?.[3]?.children[0]?.textContent
      setInitialValues({
        delay: time.split(" ")[2],
        delayPeriod: {
          value: time.split(" ")[3],
          id: time.split(" ")[3],
        }
      })
    }
  }, [automationPayload])   // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModelFormWrapper>
      <Typography variant="h6">Add Delay</Typography>
      <AppForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={DelayConfigSchema}
        innerRef={formRef}
      >
        <FieldsWrapper>
          <AppFormTimePicker label="Delay Time" name="delay" />
          <AppReactSelectField
            name={"delayPeriod"}
            options={delayPeriod}
            label="Delay Period In"
            displayKey="name"
            valueKey="id"
            placeholder="Select Delay Period"
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            isRequired={true}
            divStyle={{ background: "none", padding: "0" }}
            optionsPlaceholder="No Delay Period Found"
          />
        </FieldsWrapper>
        <AppFormObserver setFormikProps={setFormikProps} />
        <ModelFooter>
          <AppButton variant="outline" onClick={closeModel}>Cancel</AppButton>
          <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
        </ModelFooter>
      </AppForm>
    </ModelFormWrapper>
  );
};

export default AddDelay;

const ModelFormWrapper = styled.div`
  width: 600px;
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
`;
const ModelFooter = styled.div`
    display: flex;
    align-items:center;
    justify-content: flex-end;
    border-top: 1px solid ${theme.palette.default.border};
    padding: 15px 0 0;
    button:last-child {
        margin-left: 15px;
    }
`
const FieldsWrapper = styled.div`
&&{
  display:flex;
  gap:20px;
}`